<template>
  <div>
    <v-card width="1000" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>إضافة جهة خارجية</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field
                  label="الاسم"
                  placeholder="الاسم"
                  v-model="external.name"
                  :rules="externalnameRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-select
                  v-model="externalsType"
                  label="نوع الجهة الخارجية"
                  @change="checkProject"
                  :items="externalsTypes"
                  required
                ></v-select>
              </v-flex>
              <!--  <v-flex xs12 md6>
                            <v-text-field
                                label="نوع الاسمنت المستخدم"
                                placeholder="نوع الاسمنت المستخدم"
                                v-model="external.cementPackage"
                                :rules="cementPackageRules"
                                required
                            ></v-text-field>
                        </v-flex>-->
              <v-flex xs12 md6>
                <v-text-field
                  label="المالك"
                  placeholder="المالك"
                  v-model="external.owner"
                  :rules="ownerRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="showTransport">
                <v-text-field
                  label="الموقع"
                  placeholder="الموقع"
                  v-model="external.location"
                  :rules="locationRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="الموبايل"
                  placeholder="00966011XXXXXXX"
                  v-model="external.mobile"
                  :rules="mobileRules"
                  type="number"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="showTransport">
                <v-text-field
                  label="الإيميل"
                  placeholder="الإيميل"
                  v-model="external.email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="showTransport">
                <v-autocomplete
                 v-model="external.activityId"
                
                  :items="ActivityList"
                  color="white"
                  item-text="name"
                  item-value="id"
                 :rules="activityTypeRules"
                  label="نوع النشاط"
              
                  required
                ></v-autocomplete>
            
              </v-flex>
              <v-flex xs12 md6 v-if="showTransport">
                <v-autocomplete
                  v-model="transporterId"
                  :filter="customFilter"
                  :items="transporters"
                  color="white"
                  item-text="item"
                  item-value="value"
                  :rules="transportersRules"
                  label="الناقل"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6 v-if="!showTransport">
                <v-text-field
                  label="السيارات"
                  placeholder="السيارات"
                  v-model="cars"
                  type="number"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-file-input
                  v-model="photos"
                  multiple
                  label="الصور"
                ></v-file-input>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-autocomplete
                  v-model="mainContractor"
                  :filter="customFilter2"
                  :items="mainContractors"
                  color="white"
                  item-text="item"
                  item-value="value"
                  :rules="[(v) => !!v || 'المقاول الرئيسي مطلوب']"
                  label="المقاول الرئيسي"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-autocomplete
                  v-model="secondaryContractor"
                  :filter="customFilter3"
                  :items="secondaryContractors"
                  color="white"
                  item-text="item"
                  item-value="value"
                  label="المقاول الثانوي"
                  :rules="[(v) => !!v || 'المقاول الثانوي مطلوب']"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-text-field
                  label="المساحة"
                  placeholder="المساحة"
                  v-model="project.space"
                  :rules="[(v) => !!v || 'المساحة مطلوبة']"
                  required
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-text-field
                  label="التكلفة"
                  type="number"
                  placeholder="التكلفة"
                  v-model="project.cost"
                  :rules="[(v) => !!v || 'التكلفة مطلوبة']"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-text-field
                  label="المكتب الاستشاري"
                  placeholder="المكتب الاستشاري"
                  v-model="project.consultationOffice"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-text-field
                  label="المكتب الهندسي"
                  placeholder="المكتب الهندسي"
                  v-model="project.engineeringOofice"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-text-field
                  label="حالة المشروع"
                  placeholder="حالة المشروع"
                  v-model="project.projectStatus"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="addProject">
                <v-text-field
                  label="الملاحظات"
                  placeholder="الملاحظات"
                  v-model="project.details"
                  required
                ></v-text-field>
              </v-flex>
              <gmap-autocomplete class="introInput">
                <template v-slot:input="slotProps">
                  <v-text-field
                    outlined
                    v-model="external.location"
                    prepend-inner-icon="place"
                    placeholder="Location Of Event"
                    ref="input"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                  >
                  </v-text-field>
                </template>
              </gmap-autocomplete>
              <v-flex xs12 md12 v-if="showTransport">
                <GmapMap
                  :center="center"
                  :zoom="8"
                  map-style-id="roadmap"
                  :options="mapOptions"
                  style="width: 100vmin; height: 70vmin"
                  ref="mapRef"
                  @click="handleMapClick"
                >
                  <GmapMarker
                    :position="marker.position"
                    :clickable="true"
                    :draggable="true"
                    @drag="handleMarkerDrag"
                    @click="panToMarker"
                  />
                </GmapMap>
                <button @click.prevent="geolocate">Detect Location</button>
                <p>Selected Position: {{ marker.position }}</p>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveExternal()"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <!--    <v-btn @click="$router.go(-1)" style="background:gray;" dark>
                    <b>رجوع</b>
                </v-btn>-->
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPlace: null,
      marker: { position: { lat: 24.4, lng: 46.71667 } },
      center: { lat: 24.4, lng: 46.71667 },
      mapOptions: {
        disableDefaultUI: false,
      },
      showTransport: true,
      photos: [],
      externalsType: "",
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      valid: true,
      transportersRules: [
        (v) => !!v || "الناقل مطلوب",
        (v) => v.value != "0" || "الاختيار غير مقبول",
      ],
      externalsTypes: [
        "مباسط",
        "مصانع",
        "مقاولين",
        "مشاريع",
        "ناقلين",
        "مواقف",
      ],
      externalnameRules: [
        (v) => !!v || "الاسم مطلوب",
        (v) => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف",
      ],
      cars: 0,
      emailRules: [
        (v) => !!v || "الإيميل مطلوب",
        (v) => /.+@.+\..+/.test(v) || "أدخل إيميل صحيح",
      ],
      locationRules: [(v) => !!v || "الموقع مطلوب"],
      secondaryContractors: [{ item: "اختر المقاول الثانوي", value: "0" }],
      mainContractors: [{ item: "اختر المقاول الرئيسي", value: "0" }],
      ownerRules: [
        (v) => !!v || "الاسم مطلوب",
        (v) => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف",
      ],
      mobileRules: [
        (v) => !!v || "رقم الموبايل مطلوب",
        (v) =>
          (v && (v.length == 9 || v.length == 10 || v.length == 14)) ||
          "رقم الموبايل يجب أن يكون بطول 9 أو 10 أو 14 أرقام",
      ],
      activityTypeRules: [(v) => !!v || "نوع النشاط مطلوب"],
      selectedExternalType: 0,
      transporters: [{ item: "اختر ناقل", value: "0" }],
      transporterId: "",
      addProject: false,
      external: {
        name: "",
        cementPackage: "",
        owner: "",
        location: "",
        mobile: "",
        email: "",
        activityId:'',
        activityType: "",
        lastVisit: new Date().toISOString().substr(0, 10),
        transportId: "",
      },
      mainContractor: "",
      secondaryContractor: "",
      project: {
        mainContractor: "",
        secondaryContractor: "",
        space: 0,
        cost: 0,
        consultationOffice: "",
        engineeringOofice: "",
        projectStatus: "",
        details: "",
      },
      ActivityList:[]
    };
  },

  created() {
    this.getTransporters();
  },

  mounted() {
    this.geolocate();
    this.getListByActivity()
  },

  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        //  this.fetchLocationName(this.marker.position.lat,this.marker.position.lng)
        this.panToMarker();
      });
    },

    //   await fetch(
    //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA0m5kHQqsXmKml_B9mMw_yRB6SBQu2TbE`
    //   )
    //     .then(response => response.json())
    //     .then((data) => {

    //       // console.log(
    //       //   'ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson),
    //       // );
    //     })
    //     .catch(err=>console.log(err.message))
    // },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(8);
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    showAddress() {},
    showError() {
      // ..
    },
      async getListByActivity() {
      const response = await this.ApiService.get("Activity/List");

      response.data.responseData.forEach((el) => {
        this.ActivityList.push({ name: el.name, id: el.id });
      });
    },
    uploadPhotos(id) {
      var formData = new FormData();
      for (let i = 0; i < this.photos.length; i++) {
        let file = this.photos[i];
        formData.append("photos", file);
      }
      if (this.externalsType == "مشاريع") {
        formData.append("ProjectId", id);
      } else if (this.externalsType == "ناقلين") {
        formData.append("TransporterId", id);
      } else {
        formData.append("ExternalId", id);
      }
      this.ApiService.post("Photo/addphoto", formData)
        .then(() => {})
        .catch(() => {});
    },
    checkProject() {
      if (this.externalsType == "مشاريع") {
        this.showTransport = true;
        this.addProject = true;
        this.getContractors();
      } else if (this.externalsType == "ناقلين") {
        this.showTransport = false;
        this.addProject = false;
      } else {
        this.showTransport = true;
        this.addProject = false;
      }
    },
    saveExternal() {
      if (this.$refs.form.validate()) {
        let data = null;
        let url = null;
        if (this.externalsType == "مصانع") {
          this.external.cementPackage = 1;
        }
        if (this.externalsType == "مقاولين") {
          this.external.cementPackage = 3;
        }
        if (this.externalsType == "مباسط") {
          this.external.cementPackage = 4;
        }
        if (this.externalsType == "مشاريع") {
          this.external.cementPackage = 2;
        }
        if (this.externalsType == "مواقف") {
          this.external.cementPackage = 5;
        }
        if (!this.addProject) {
          url = "external/addexternal";
          data = {
            name: this.external.name,
            cementPackage: this.external.cementPackage,
            owner: this.external.owner,
            location: this.external.location,
            mobile: this.external.mobile,
            email: this.external.email,
            activityId:this.external.activityId,
            activityType: this.external.activityType,
            lastVisit: this.external.lastVisit,
            transporterId: this.transporterId.value,
            lat: this.marker.position.lat,
            lng: this.marker.position.lng,
          };
        } else {
          url = "external/addproject";
          data = {
            external: {
              name: this.external.name,
              cementPackage: this.external.cementPackage,
              owner: this.external.owner,
              location: this.external.location,
              mobile: this.external.mobile,
              email: this.external.email,
                activityId:this.external.activityId,
              activityType: this.external.activityType,
              lastVisit: this.external.lastVisit,
              transporterId: this.transporterId.value,
              lat: this.marker.position.lat,
              lng: this.marker.position.lng,
            },
            project: {
              mainContractor: this.mainContractor.value,
              secondaryContractor: this.secondaryContractor.value,
              space: this.project.space,
              cost: this.project.cost,
              consultationOffice: this.project.consultationOffice,
              engineeringOofice: this.project.engineeringOofice,
              projectStatus: this.project.projectStatus,
              details: this.project.details,
            },
          };
        }
        if (this.externalsType == "ناقلين") {
          url = "Transporters/addtransporter";
          data = {
            cars: this.cars,
            idtransporter: 0,
            name: this.external.name,
            owner: this.external.owner,
            mobile: this.external.mobile,
            lastVisit: "",
            disable: false,
            carts: [],
          };
        }
        this.ApiService.post(url, data)
          .then((res) => {
            let id = null;
            if (this.externalsType == "مشاريع") {
              id = res.data.responseData.project.idproject;
            } else if (this.externalsType == "ناقلين") {
              id = res.data.responseData.idtransporter;
            } else {
              id = res.data.responseData.idexternal;
            }
            if (this.photos != null) {
              this.uploadPhotos(id);
            }
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.$router.push({ name: "externals" });
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    async getContractors() {
      this.secondaryContractors = [
        { item: "اختر المقاول الثانوي", value: "0" },
      ];
      this.mainContractors = [{ item: "اختر المقاول الرئيسي", value: "0" }];
      await this.ApiService.get("external/getallexternals?id=3")
        .then((res) => {
          var allContractors = res.data.responseData;
          allContractors.forEach((el) => {
            this.mainContractors.push({ item: el.name, value: el.idexternal });
            this.secondaryContractors.push({
              item: el.name,
              value: el.idexternal,
            });
          });
        })
        .catch(() => {});
    },
    async getTransporters() {
      (this.transporters = [{ item: "اختر ناقل", value: "0" }]),
        this.ApiService.get("Transporters/getalltransporters")
          .then((res) => {
            var allTransporters = res.data.responseData;
            allTransporters.forEach((el) => {
              this.transporters.push({
                value: el.idtransporter,
                item: el.name,
              });
            });
          })
          .catch(() => {});
    },
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilter2(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilter3(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
  }
};
</script>

<style>
input {
  direction: rtl;
}
</style>